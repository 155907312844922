@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #47a3f3;
  color: #fefefe;
}

html {
  min-width: 360px;
  scroll-behavior: smooth;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sticky-nav {
  position: sticky;
  z-index: 10;
  top: 0;
  backdrop-filter: saturate(180%) blur(20px);
  transition: background-color 0.1 ease-in-out;
}

.prose pre {
  @apply bg-gray-50 border border-gray-200 dark:border-gray-700 dark:bg-gray-900;
}

.prose code {
  @apply text-gray-800 dark:text-gray-200 px-1 py-0.5 border border-gray-100 dark:border-gray-800 rounded-md dark:bg-gray-900;
  border: none;
}

.prose img {
  /* Don't apply styles to next/image */
  @apply m-0;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  @apply text-gray-700 dark:text-gray-300;
}

.token.punctuation {
  @apply text-gray-700 dark:text-gray-300;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  @apply text-green-500;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  @apply text-purple-500;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  @apply text-yellow-500;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  @apply text-blue-500;
}

.token.function,
.token.class-name {
  @apply text-pink-500;
}

.token.regex,
.token.important,
.token.variable {
  @apply text-yellow-500;
}

code[class*="language-"],
pre[class*="language-"] {
  @apply text-gray-800 dark:text-gray-50;
}

pre::-webkit-scrollbar {
  display: none;
}

pre {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.remark-code-title {
  @apply text-gray-800 dark:text-gray-200 px-5 py-3 border border-b-0 border-gray-200 dark:border-gray-700 rounded-t bg-gray-200 dark:bg-gray-800 text-sm font-mono font-bold;
}

.remark-code-title + pre {
  @apply mt-0 rounded-t-none;
}

.mdx-marker {
  @apply block -mx-4 px-4 bg-gray-100 dark:bg-gray-800 border-l-4 border-blue-500;
}

/* Remove Safari input shadow on mobile */
input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.metric-card > a {
  @apply no-underline;
}

.metric-card > p {
  @apply my-2;
}

.step > h3 {
  @apply my-0;
}

/* SAM CHANGES */

a {
  @apply hover:text-blue-500 hover:dark:text-blue-500;
  text-decoration: underline;
}

button {
  @apply text-lg rounded shadow text-current focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply bg-gray-200 dark:bg-gray-600 hover:bg-blue-200 dark:hover:bg-blue-600 disabled:bg-gray-400 dark:disabled:bg-gray-800 #{!important};
}

.dark .dark\:prose-dark :where(h1):not(:where([class~="not-prose"] *)) {
  color: #f3f4f6;
}
